import { createWebHistory,createRouter } from 'vue-router'
// import MPage from './components/MPage.vue'
import {routes} from './utils/converter';

// const routes = [
//     {path: '/about', name: 'about', component: MPage}
// ]

// const routes = [
// 	{
// 		"name": "chapter01",
// 		"component": MPage,
// 		"path": "/chapter01",
//     "meta": { uniqueKey: 'chapter01' }
// 	},
// 	{
// 		"name": "about",
// 		"component": MPage,
// 		"path": "/about",
// 		// "children": []
//     "meta": { uniqueKey: 'about' }
// 	},
//   {
//     "name": "page01",
//     "component": MPage,
//     "path": "/chapter01/page01",
//     // "children": []
//     "meta": { uniqueKey: 'page01' }
//   },
//   {
//     "name": "page02",
//     "component": MPage,
//     "path": "/chapter01/page02",
//     // "children": []
//     "meta": { uniqueKey: 'page02' }
//   }
// ]


const router = createRouter({
  history: createWebHistory(),
  routes: routes(),
})

export default router;