import { createApp } from 'vue'
import App from './App.vue'

// 引入全局样式表
import './assets/styles.css';
import router from './router';

// highlight 的样式，依赖包，组件
// import hljs from "highlight.js";
import "highlight.js/styles/github.css";

// import Highlight from './utils/highlight.js';

const app = createApp(App);
app.use(router).mount('#app')