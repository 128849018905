<template>
    <!-- <div id="catalog"> -->
      <ul>
        <li v-for="(node, index) in flattenedChapters" :key="index" :style="{ marginLeft: `${node.depth * 20}px` }">
          <router-link class="chapter" :to="`${node.path}`">{{ node.title }}</router-link>
        </li>
      </ul>
    <!-- </div> -->
  </template>
  
  <script>
  export default {
    name: 'BookCatalog',
    props: {
      chapters: Array
    },
    computed: {
      flattenedChapters() {
        return this.flattenChapters(this.chapters, 0);
      }
    },
    methods: {
      flattenChapters(chapters, depth) {
        let result = [];
        chapters.forEach(node => {
          result.push({ ...node, depth });
          if (node.children) {
            result.push(...this.flattenChapters(node.children, depth + 1));
          }
        });
        return result;
      }
    }
  };
  </script>

<style scoped>

    #catalog {
        border: 1px solid red;
    }

    .chapter {
        padding: 6px 15px;
        /* border: 1px solid #ccc; */
        display: block;
        font-family: "Open Sans","Clear Sans","Helvetica Neue",Helvetica,Arial,sans-serif;    
        font-size: 14px;
    }

    ul {
        list-style-type: none !important;
        padding:0 0 0 20px;
        margin: 0;
        list-style: none !important; 
    }

    li {
        margin: 0; /* 去除外边距 */
        list-style: none !important;
        font-weight: normal;
    }

    a {
        text-decoration: none; /* 去除下划线 */
        color: inherit; /* 设置链接的文字颜色与父元素一致，或者根据需要修改颜色值 */
    }
</style>