<template>
  <div class="header-wrapper">
    <div id="header">
      <div class="logo"><img src="./assets/logo.jpg"/></div>
      <div class="nav">
        <a href="#" 
          v-for="(item, index) in sideNavItems" 
          :key="index" 
          class="nav-link" 
          :class="{ 'active-underline': item.active }"
          @click="setActive(index)"
        >{{ item.name }}</a>
      </div>
      <div class="toggle">
        <img src="./assets/toggle.png" @click="toggleSummary"/>
      </div>
    </div>
  </div>
  <div id="book">
    <transition name="slide">
      <div class="book-summary" v-show="isSummaryVisible" ref="bookSummary">
        <book-summary :chapters="chapters"></book-summary>
      </div>
    </transition>
    <div class="book-summary-site" v-show="isSummaryVisible">

    </div>
    <div class="book-body" @click="hideSummary">
      <router-view/>
    </div>
  </div>
  <div id="footer">
    <div class="place">

    </div>
    <div class="icp">
      <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2024083664号-1</a>
      <span>Copyright © 2024 CoderBook</span>
    </div>
  </div>
</template>

<script>
import {chapter} from './utils/converter';
import BookCatalog from './components/BookCatalog.vue';

export default {
  components: {
    // 在当前Vue文件中注册BookSummary组件
    'book-summary': BookCatalog
  },
  data() {
    return {
      sideNavItems: [{name: 'JAVA梳理', active: true}],
      chapters: chapter(),
      activeIndex: 0,
      isSummaryVisible: true
    };
  },
  mounted(){
    console.log("--- mounted ---")
  },
  methods: {
    setActive(index) {
      this.sideNavItems.forEach(item => {
            item.active = false;
      });

      this.sideNavItems[index].active = true; // 设置当前点击的导航项为激活状态
    },
    toggleSummary() { 
      this.isSummaryVisible = !this.isSummaryVisible;
    },
    hideSummary() {
      if(this.isSummaryVisible) {
        this.isSummaryVisible = false
      }
    }
  }
}
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.header-wrapper {
  height: 65px;
  width: 100%;
}

#header {
  display: flex;
  position: fixed;
  width: 100%;
  height: 65px;
  top: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #eaecef;
  z-index: 11;
  align-items: center;
}

.logo {
  display: flex;
  width: 300px;
  height: 65px;

  align-items: center;
  justify-content: center;
}

.logo img { 
  width: auto; 
  height: 65px;
  object-fit: contain;
}

.nav {
  margin-left: 50px;
  width: auto;
  display: flex;
  padding: 0 8px;
}

.nav-link {
  width: 100px;
  text-decoration: none;
  color: inherit;
  text-align: center;
  padding: 4px 0;
  margin: 0 6px;
  font-size: 16px;
}

.toggle {
  display: flex;
  width: 65px;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.toggle img {
  width: 26px;
  height: 26px;
}

.active-underline {
  border-bottom: 3px solid #51bfff;
  transition: border-bottom 0.3s ease;
}

.nav-link:hover {
  border-bottom: 3px solid #51bfff;
}

body {
  margin: 0 !important;
}

#book {
  display: flex;
  min-height: 100vh;
}

.book-summary-site {
  display: flex;
  width: 300px;
}

.book-body {
  display: flex;
  flex: 1;
  padding: 10px 30px;
  overflow-y: auto;
  height: 100%;
  position: relative;
  justify-content: center;
  z-index: 1;
}

.book-summary {
  width: 300px;
  background: #fafafa;
  display: flex;
  position: fixed;
  flex-direction: column;
  margin: 0px;
  bottom: 0;
  padding: 10px 0px;
  overflow-y: auto;
  z-index: 10;
  top: 65px;
}

@media (max-width: 768px) {
    #write {
      max-width: 90%;
    }

    .book-summary-site {
      display: none;
    }

    .place {
      display: none;
    }

    .header-wrapper {
      height: 60px;
    }

    #header {
      height: 60px;
      justify-content: space-between;
    }

    .logo {
      width: 100px;
      height: 60px;
    }

    .logo img {
      height: 60px;
    }

    .nav {
      margin-left: auto; /* 右边距自动调整以适应空间 */
      margin-right: auto; /* 左边距自动调整以适应空间 */
    }

    .book-summary {
      top: 60px;
    }

    .toggle {
      display: flex;
      height: 60px;
      width: 60px;
    } 

    .toggle img {
      width: 26px;
      height: 26px;
    }

    .icp {
      padding: 0 15px;
    }
}

.book-summary::-webkit-scrollbar {
  width: 6px;
}

.book-summary::-webkit-scrollbar-track {
  background: #fafafa;
}

.book-summary::-webkit-scrollbar-thumb {
  background: #dee1e6;
}

#footer {
  display: flex;
  position: relative;
  width: 100%;
  height: 60px;
  background: #fff;
  margin-top: auto;
}

.place {
  width: 300px;
}

.icp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  flex: 1;
  color: #c8c8c8;
}

.icp a,
.icp span {
  margin: 0 auto; 
}

</style>