import BookArticle from '../components/BookArticle.vue';
// import Catalog from '../config'

const Catalog = [
    {
        path: '/about', 
        name: 'about',
        title: '关于本站',
    },
    {
        path: '/interview', 
        name: 'interview',
        title: '面试准备',
        children: [
            {
                path: 'resume', 
                name: 'resume',
                title: '简历优化',
            }
        ]
    },
    {
        path: '/mustknow', 
        name: 'mustknow',
        title: '必知必会',
        children: [
            {
                path: 'java', 
                name: 'java',
                title: 'Java',
                children: [
                    {
                        path: 'collection', 
                        name: 'collection',
                        title: 'Java 集合',
                    },
                    {
                        path: 'thread', 
                        name: 'thread',
                        title: 'Java 多线程',
                        children: [
                            {
                                path: 'threadpool', 
                                name: 'threadpool',
                                title: 'ThreadPool',
                            },
                            {
                                path: 'threadlocal', 
                                name: 'threadlocal',
                                title: 'ThreadLocal',
                            },
                            {
                                path: 'aqs', 
                                name: 'aqs',
                                title: 'AQS',
                            }
                        ]
                    },
                    {
                        path: 'jvm', 
                        name: 'jvm',
                        title: 'JVM',
                    },
                ]
            },
            {
                path: 'mysql', 
                name: 'mysql',
                title: 'MySQL',
                children: [
                    {
                        path: 'transaction', 
                        name: 'transaction',
                        title: 'MySQL 事务',
                    },
                    {
                        path: 'index', 
                        name: 'index',
                        title: 'MySQL 索引',
                    },
                    {
                        path: 'mvcc', 
                        name: 'mvcc',
                        title: 'MVCC',
                    }
                ]
            },
            {
                path: 'framework', 
                name: 'framework',
                title: '开发框架',
                children: [
                    {
                        path: 'spring', 
                        name: 'spring',
                        title: 'Spring',
                    },
                ]
            },
            {
                path: 'linux', 
                name: 'linux',
                title: 'Linux',
                children: [
                    {
                        path: 'zerocopy', 
                        name: 'zerocopy',
                        title: '零拷贝',
                    },
                ]
            },
            {
                path: 'distributed-transaction', 
                name: 'distributed-transaction',
                title: '分布式事务',
            },
            {
                path: 'mq', 
                name: 'mq',
                title: 'MQ',
            },
            {
                path: 'redis', 
                name: 'redis',
                title: 'Redis',
            },
            {
                path: 'timewheel', 
                name: 'timewheel',
                title: '时间轮',
            },
            {
                path: 'io-multiplex', 
                name: 'io-multiplex',
                title: 'I/O 多路复用',
            },
            {
                path: 'spi', 
                name: 'spi',
                title: 'SPI',
            },
            {
                path: 'design-pattern', 
                name: 'design-pattern',
                title: '设计模式',
            },
        ]
    },
    {
        path: '/distributed', 
        name: 'distributed',
        title: '分布式技术',
        children: [
            {
                path: 'raft', 
                name: 'raft',
                title: 'Raft 算法',
            },
            {
                path: 'hystrix', 
                name: 'hystrix',
                title: 'Hystrix',
            },
            {
                path: 'flink', 
                name: 'flink',
                title: 'Flink',
            },
        ]
    },
    {
        path: '/other', 
        name: 'other',
        title: '建站记录',
        children: [
            {
                path: 'picture-bed', 
                name: 'picture-bed',
                title: '图床—免费的图片存储方案',
            },
            {
                path: 'xxx', 
                name: 'xxx',
                title: 'xxx',
            }
        ]
    },
]

export function chapter() {
    console.log(JSON.stringify(convertCatalogToChapter(Catalog)))
    return convertCatalogToChapter(Catalog);
}

function convertCatalogToChapter(catalog, parentPath = '') {
    return catalog.reduce((acc, item) => {
        const currentPath = parentPath ? `${parentPath}/${item.path}` : item.path;
        const currentChapter = {
            id: item.name,
            title: item.title,
            name: item.name,
            path: currentPath,
        };
        if (item.children) {
            currentChapter.children = convertCatalogToChapter(item.children, currentPath);
        }
        return acc.concat(currentChapter);
    }, []);
}

export function routes() {
    return convertCatalogToRoutes(Catalog);
}

function convertCatalogToRoutes(catalog) {
    return catalog.map(item => ({
        name: item.name,
        component: BookArticle,
        // 拼接父节点的路径和当前节点的路径
        path: item.path,
        children: item.children ? convertCatalogToRoutes(item.children) : null
    }));
}