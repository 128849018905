<template>
  <div id="write" class="content" v-html="content"></div>
</template>

<script>
import { marked } from 'marked';
import axios from 'axios';
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
// import theme from '../config'

export default {
  data() {
    return {
      content: '',
    };
  },
  mounted() {
    console.log("mounted")

    // let link = document.createElement('link');
    // link.type = 'text/css';
    // link.id = "theme";
    // link.rel = 'stylesheet';
    // //默认引入css文件这里，我出的错
    // link.href = '/' + 'han' + '.css';//正确，精准地址
    // var head = document.getElementsByTagName("head")[0]

    // head.appendChild(link); 
  },
  updated() {
    console.log("updated")
    var blocks = document.querySelectorAll("pre code");

    blocks.forEach((block) => {
      // 创建行号容器
      const ul = document.createElement("ul");
      ul.className = "pre-numbering";
      // 插入到代码块的前面
      block.parentNode.insertBefore(ul, block);

      // 获取代码块的文本内容并分割成行
      const lines = block.textContent.split("\n");
      // 为每一行生成行号，并添加到行号容器中
      for (let i = 1; i < lines.length; i++) {
        const li = document.createElement("li");
        li.textContent = String(i); // 注意这里要加1，因为通常从1开始编号而不是从0
        ul.appendChild(li);
      }

      // 进行代码高亮处理（确保行号已经添加到DOM中）
      hljs.highlightElement(block);
    });
  },
  beforeRouteEnter() {
    console.log("beforeRouteEnter") 
  },
  beforeRouteUpdate() {
    console.log("beforeRouteUpdate")
  },
  async created(){
    console.log("created: " + this.$route.path)

    const markdownFilePath = this.$route.path + '.md';
    console.log(markdownFilePath)
    this.fetchHtmlContent(markdownFilePath);    
  },
  computed: {
    // content() {
    //   console.log("content")
    //   return marked(this.textData);
    // },
  },
  watch: {
    '$route': async function(to, from) {
      console.log("from:"+from.path + "->to:"+to.path)
      console.log(this.$route.name)
      
      // 当路由变化时执行数据获取逻辑
      const markdownFilePath = to.path + '.md';
      console.log(markdownFilePath)
      this.fetchHtmlContent(markdownFilePath);
    }
  },
  methods: {
    async fetchHtmlContent(path) {
      const textData = await this.fetchMarkdownContent(path);
      this.content = marked(textData);
    },
    async fetchMarkdownContent(path) {
      try {
        const response = await axios.get(path);
        return response.data;
      } catch (error) {
        console.error('Error fetching Markdown content:', error);
        return '';
      }
    }
  }
};
</script>

<style scope>
  @import url('../assets/css/drake-juejin.css');

  #write {
    max-width: 80%;
  }

  img {
    max-width: 100% !important;
  }

  pre {
    position: relative;
    background-color: #fafafa;
    font-size: 15px;
    padding: 8px 8px 0px 12px;
    border: 1px solid #ccc;
  }
 
  .hljs {
    background-color: #fafafa !important;
    line-height: 22px !important;
    padding: 0 0 0.5em 2.2em !important;
    white-space: unset;
    max-width: 700px;
    border: none !important;
    margin: 0 !important;
  }

  .hljs::-webkit-scrollbar {
    height: 6px;
  }

  .hljs::-webkit-scrollbar-track {
    background: #fafafa;
  }

  .hljs::-webkit-scrollbar-thumb {
    background: #dee1e6;
    border-radius: 6px;
  }

  pre ul, pre li {
    margin: 0 !important;
    padding: 0;
    padding-left: 0 !important;
    top: 0;
  }

  pre {
    display: flex;
  }

  pre ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 7px;
    padding-bottom: 8px;
  }
  
  .pre-numbering {
    position: absolute;
    left: 0;
    width: 30px;
    border-right: 1px solid #ccc;
    background: #fafafa;
    text-align: center;
    line-height: 22px;
    z-index: 9;
  }

  .pre-numbering li {
    list-style: none;
    color: #383a42;
    font-size: 15px;
    line-height: 22px;
  }

  .pre-numbering li::before {
    content: none !important;
  }

  #write ul > li:not([class*=task-list-item]):before {
    top: inherit;
  }

</style>